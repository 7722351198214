<!-- =========================================================================================
    File Name: ApexCharts.vue
    Description: Apex Charts Demo
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent minDate: fromdate,
========================================================================================== -->

<template>
<div>
   <div class="dashboard-filters" style="background: white;" v-if="false">
      <div class="vs-row" style="display: flex; width: 100%;" v-if="false">
         <div class="vs-col vs-xs- vs-sm- vs-lg-" style="justify-content: center; display: flex; align-items: center; margin-left: 0%; width: 30%;">
            <div class="org-filter input_section">
               <flat-pickr style="height: 40px;" v-model="from_model" placeholder="From Date" :config="{ maxDate: new Date() }" class="w-full" name="from_model"/>
            </div>
         </div>
         <div class="vs-col vs-xs- vs-sm- vs-lg-" style="position: relative;left: 20px;justify-content: center; display: flex; align-items: center; margin-left: 0%; width: 30%;">
            <div class="input_section">
               <flat-pickr style="height: 40px;" v-model="to_model" placeholder="To Date" :config="{  maxDate: new Date() }" class="w-full" name="to_model"/>
            </div>
         </div>
         <div class="btn_dashboard">
            <vs-button style="margin: 0px 13px;" class="ml-auto mt-2" @click="pdfprint(org_name)">Print Dashboard
            </vs-button>
            <a  class="ml-auto mt-2" href="javascript:void(window.open('https://form.jotform.com/212012938466455','blank',
               'scrollbars=yes,toolbar=no,width=1200,height=900'))">
               <vs-button class="ml-auto mt-2"> Take survey</vs-button>
            </a>
         </div>
      </div>
   </div>
   <!--This is for tab headings-->
   <!-- <vs-row class="tab_heading_container">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="tab_heading_item_parent"
         :class="{tab_heading_active: visible_tab==='one'}">
         <span @click="tabclick('one')" class="tab_heading_item">Tab-1</span>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="tab_heading_item_parent"
         :class="{tab_heading_active: visible_tab==='two'}">
         <span @click="tabclick('two')" class="tab_heading_item">Tab-2</span>
      </vs-col>
   </vs-row> -->


   <!-- == ONE == -->
   <div v-if="visible_tab==='one'">
      <vs-row>
         <vs-col vs-w="12">
            <div class="vx-col w-full mb-base insight">
               <vx-tooltip color="primary" text="Executive Insights" position="left">
                  <vx-card title="Executive Insights ">
                     To be entered by data scientists
                  </vx-card>
               </vx-tooltip>
            </div>
         </vs-col>
      </vs-row>
      <vs-row>
         <vs-col vs-w="12">
            <!-- sunburst chart -->
            <vs-row>
               <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" >
                  <v-chart class="chart" :option="category_option" v-once/>
               </vs-col>
            </vs-row>
         </vs-col>
      </vs-row>
      <vs-divider>Default</vs-divider>
      <br>
   </div>
   <!-- == TWO == -->
   <div v-if="visible_tab==='two'">
      <vs-row>
         <vs-col vs-w="12">
            <vs-row>
               <!-- interval OSD -->
               <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6" >
                  <div class="title_range">
                     Showing data for the interval between <b>{{formatReadable(date_from, 'll')}}</b> and <b>{{formatReadable(date_to, 'll')}}</b>
                  </div>
               </vs-col>
               <!-- datepicker : icon-->
               <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="6" >
                  <vs-button @click="is_datepik_visible=true" color="success" type="flat">
                     <vs-icon icon="event"></vs-icon>
                  </vs-button>
               </vs-col>
            </vs-row>
            <!-- datepicker -->
            <Fx-Modal v-show="is_datepik_visible" @close="closeDatePik">
               <template v-slot:header></template>
               <template v-slot:body>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                     <DateRangePicker :panel="'week'" :panels="['week', 'month', 'quarter', 'year']" @update="datepikUpdate" :future="false"></DateRangePicker>
                  </vs-col>
               </template>
               <template v-slot:footer>
               </template>
            </Fx-Modal>


            <vs-row>
               <vs-col vs-w="12">
                  <!-- line chart -->
                  <vs-row>
                     <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" >
                        <v-chart class="chart" :option="mau_dau_option" v-once/>
                     </vs-col>
                  </vs-row>
               </vs-col>
            </vs-row>


         </vs-col>
      </vs-row>
      <vs-divider>Default</vs-divider>
      <br>
   </div>
</div>
</div>
</template>



<style scoped>
.chart {
  height: 100vh;
}
</style>

<style>
body {
  margin: 0;
}
</style>

<script>

import Vue from 'vue'

import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
// cust: https://vuejsexamples.com/vue-js-date-range-picker-with-multiples-ranges-and-presets/

Vue.use(DateRangePicker)

import FxModal from './new-dash/blocks/Modal'

var sdata = [
  {
    name: 'Get centered',
    itemStyle: {
      color: '#3465a4'
    },
    children: [
      {
        name: 'Self Care',
        value: 6,
        itemStyle: {
          color: '#376bae'
        }
      },
      {
        name: 'Music',
        itemStyle: {
          color: '#5185c8'
        },
        value: 4,
      }
    ]
  },
  
    {
    name: 'Social',
    itemStyle: {
      color: '#4e9a06'
    },
    children: [
      {
        name: 'isabel offers',
        value:3,
        itemStyle: {
          color: '#89f622'
        }
      }
    ]
  },
  
    {
    name: 'Get Active',
    itemStyle: {
      color: '#f57900'
    },
    children: [
      {
        name: 'Workout',
        value: 10,
        itemStyle: {
          color: '#e67100'
        }
      },
      {
        name: 'Our Story',
        itemStyle: {
          color: '#ff8b1a'
        },
        value: 11,
      },
      {
        name: 'Nutrition',
        itemStyle: {
          color: '#ffa54d'
        },
        value: 9,
      }
    ]
  },
  
     
    {
    name: 'Financial',
    itemStyle: {
      color: '#5c3566'
    },
    children: [
      {
        name: 'Financial well-being',
        value: 5,
        itemStyle: {
          color: '#884e97'
        }
      }
    ]
  },
];
 


//core
import { use } from "echarts/core";

//render
import { CanvasRenderer } from "echarts/renderers";

//chart
import { PieChart, SunburstChart, LineChart } from "echarts/charts";

//component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";

import VChart, { THEME_KEY } from "vue-echarts";

//install
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SunburstChart,
  GridComponent,
  LineChart,
  ToolboxComponent
]);


import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData.js";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment'


export default {
  data() {

    return {

      is_loading: true,
      visible_tab: 'one',
      is_datepik_visible: false,
      
      date_from: '',
      date_to: '',
      date_window: '',


      showContent: "",
      fromdate: localStorage.getItem("from_date"),
      TotalEmployees: "",
      AllTotalEmployees: "",
      ActiveEmployees: "",
      TotalwatchedVideo: "",
      roomdata: "",
      Totalroomcount: "",
      Totalroomintcount: "",
      Totaleventcount: "",
      Totalchatcount: "",
      Totalcallcount: "",
      apexChatData,
      orgInsights: {},
      UserInteractionGraph: {},
      subscribersGained: {},
      RetentionlineAreaChartSpline: {},
      radialBarChart: {},
      chatemployeelog: "",
      org_name: localStorage.getItem("org_name"),
      displayPrompt: false,
      promptName: "",

      //Graph-1
      category_option: {
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        title: {
          text: 'Employee Interests',
          subtext: '',
          textStyle: {
            fontSize: 18,
            align: 'center'
          },
          subtextStyle: {
            align: 'center'
          },
          sublink: 'https://worldcoffeeresearch.org/work/sensory-lexicon/'
        },
        series: {
          type: 'sunburst',
          data: sdata,  //<--- data
          radius: [0, '95%'],
          sort: undefined,
          emphasis: {
            focus: 'ancestor'
          },
          levels: [
            {},
            {
              r0: '15%',
              r: '35%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                rotate: 'tangential'
              }
            },
            {
              r0: '35%',
              r: '90%',
              label: {
                align: 'right'
              }
            },
            {
              r0: '70%',
              r: '72%',
              label: {
                position: 'outside',
                padding: 3,
                silent: false
              },
              itemStyle: {
                borderWidth: 3
              }
            }
          ]
        }
      },

      //Graph-2
      mau_dau_option: {
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        color: ['#5470C6', '#EE6666'],
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {},
        grid: {
          top: 70,
          bottom: 50
        },
        xAxis: [
          //dau -- X
          {
            type: 'time',
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#5470C6'
              }
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    'Precipitation  ' +
                    params.value +
                    (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                  );
                }
              }
            },
            // prettier-ignore
            data: [
              new Date('2016-10-01').getTime(), 
              new Date('2016-10-02').getTime(), 
              new Date('2016-10-03').getTime(), 
              new Date('2016-10-04').getTime(), 
              new Date('2016-10-05').getTime(), 
            ]
          },
          //mau -- X
          {
            type: 'time',
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#EE6666'
              }
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    'Precipitation  ' +
                    params.value +
                    (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                  );
                }
              }
            },
            // prettier-ignore
            data: [
              new Date('2016-10-01').getTime(), 
              new Date('2016-10-02').getTime(), 
              new Date('2016-10-03').getTime(), 
              new Date('2016-10-04').getTime(), 
              new Date('2016-10-05').getTime(), 
            ]
          }
        ],


        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'DAU',
            type: 'line',
            xAxisIndex: 1,
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: [
              50, 40, 30, 20, 10
            ]
          },
          {
            name: 'MAU',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: [
              10, 20, 30, 40, 50
            ]
          }
        ]
      }
      
    }
  },

  components: {
    VueApexCharts,
    StatisticsCardLine,
    flatPickr,

    // 'v-chart': ECharts
    // ECharts
    VChart,

    FxModal
  },
  provide: {
    [THEME_KEY]: "light"
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    from_model: {
      get() {
        localStorage.setItem("load_create", 1);
        return 0;
      },
      set(date) {
        var load_create = localStorage.getItem("load_create");
        if (load_create == 1) {
          localStorage.setItem("load_create", 0);
          return 0;
        }
        this.$vs.loading();
        localStorage.setItem("from_date", date);
        var todateFilter = localStorage.getItem("to_date");
        var fromdateFilter = localStorage.getItem("from_date");
        this.$http
            .get(
                "organization/dashboard?org_id=" +
                this.$store.state.AppActiveUser.org_id +
                "&&todate=" +
                todateFilter +
                "&&fromdate=" +
                fromdateFilter
            )
            .then((response) => {
              var engagementVal = [];
              var engagementcategoryName = [];
              for (
                  let emp = 0;
                  emp < response.data.employeesUtilize.length;
                  emp++
              ) {
                engagementVal.push(response.data.employeesUtilize[emp].count);
                engagementcategoryName.push(
                    response.data.employeesUtilize[emp].category_name
                );
              }
              // if(response.data.employeesUtilize.length == 0){
              //   this.showContent = true
              // }else{
              //   this.showContent = false
              // }
              if (response.data.orgInsights != null) {
                this.orgInsights = response.data.orgInsights;
              }

              (this.RetentionlineAreaChartSpline = {
                series: [
                  {
                    name: "Projected Retention",
                    data: [70, 60, 50, 40, 40, 40, 40, 40, 40, 40, 40, 40],
                  },
                  {
                    name: "Active Users",
                    data: [
                      // response.data.userweek_count['1userweek_count'],
                      // response.data.userweek_count['2userweek_count'],
                      // response.data.userweek_count['3userweek_count'],
                      // response.data.userweek_count['4userweek_count'],
                      // response.data.userweek_count['5userweek_count'],
                      // response.data.userweek_count['6userweek_count'],
                      // response.data.userweek_count['7userweek_count'],
                      // response.data.userweek_count['8userweek_count'],
                      // response.data.userweek_count['9userweek_count'],
                      // response.data.userweek_count['10userweek_count'],
                      // response.data.userweek_count['11userweek_count'],
                      // response.data.userweek_count['12userweek_count'],
                    ],
                  },
                ],
                chartOptions: {
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },

                  tooltip: {
                    x: {
                      format: "dd/MM/yy HH:mm",
                    },
                  },
                },
              }),
                  (this.radialBarChart = {
                    series: engagementVal,
                    chartOptions: {
                      plotOptions: {
                        radialBar: {
                          dataLabels: {
                            name: {
                              fontSize: "22px",
                            },
                            value: {
                              fontSize: "16px",
                            },
                          },
                        },
                      },
                      legend: {
                        show: true,
                        position: "bottom",
                        horizontalAlign: "center",
                      },
                      labels: engagementcategoryName,
                    },
                  }),
                  (this.TotalEmployees = response.data.statisticsData[0].totalEmp);
              this.AllTotalEmployees =
                  response.data.statisticsData[0].alltotalEmp;

              //this.ActiveEmployees =  Math.ceil(response.data.addActiveemp *  (response.data.statisticsData[0].totalEmp / 100 ))


              this.TotalwatchedVideo = response.data.statisticsData[0].watched_count;

              if (response.data.statisticsData[0].room_interaction_count == null) {
                this.Totalroomintcount = 0;
              } else {
                this.Totalroomintcount = response.data.statisticsData[0].room_interaction_count;
              }

              if (response.data.statisticsData[0].room_count == null) {
                this.Totalroomcount = 0;
              } else {
                this.Totalroomcount = response.data.statisticsData[0].room_count;
              }


              this.Totaleventcount = response.data.totatActiveEvents;
              this.Totalchatcount = response.data.chatcount;
              this.Totalcallcount = response.data.callcount;

              this.$vs.loading.close();
            })
            .catch((error) => {
              console.log(error);
            });
      },
    },
    to_model: {
      get() {
        localStorage.setItem("load_create", 1);
      },
      set(date) {
        var load_create = localStorage.getItem("load_create");
        if (load_create == 1) {
          return 0;
        }
        this.$vs.loading();
        var currentDate1 = new Date(date);
        var currentMonth = currentDate1.getMonth() + 1;
        if (currentMonth < 10) {
          currentMonth = "0" + currentMonth;
        } else {
          currentMonth = "" + currentMonth;
        }
        const todate = `${currentDate1.getFullYear()}-${currentMonth}-${currentDate1.getDate()}`;
        localStorage.setItem("to_date", todate);
        var todateFilter = localStorage.getItem("to_date");
        var fromdateFilter = localStorage.getItem("from_date");
        this.$http
            .get(
                "organization/dashboard?org_id=" +
                this.$store.state.AppActiveUser.org_id +
                "&&todate=" +
                todateFilter +
                "&&fromdate=" +
                fromdateFilter
            )
            .then((response) => {
              let engagementVal = [];
              let engagementcategoryName = [];
              for (
                  let emp = 0;
                  emp < response.data.employeesUtilize.length;
                  emp++
              ) {
                engagementVal.push(response.data.employeesUtilize[emp].count);
                engagementcategoryName.push(
                    response.data.employeesUtilize[emp].category_name
                );
              }
              // if(response.data.employeesUtilize.length == 0){
              //   this.showContent = true
              // }else{
              //   this.showContent = false
              // }
              if (response.data.orgInsights != null) {
                this.orgInsights = response.data.orgInsights;
              }
              (this.RetentionlineAreaChartSpline = {
                series: [
                  {
                    name: "Projected Retention",
                    data: [70, 60, 50, 40, 40, 40, 40, 40, 40, 40, 40, 40],
                  },
                  {
                    name: "Active Users",
                    data: [
                      // response.data.userweek_count['1userweek_count'],
                      // response.data.userweek_count['2userweek_count'],
                      // response.data.userweek_count['3userweek_count'],
                      // response.data.userweek_count['4userweek_count'],
                      // response.data.userweek_count['5userweek_count'],
                      // response.data.userweek_count['6userweek_count'],
                      // response.data.userweek_count['7userweek_count'],
                      // response.data.userweek_count['8userweek_count'],
                      // response.data.userweek_count['9userweek_count'],
                      // response.data.userweek_count['10userweek_count'],
                      // response.data.userweek_count['11userweek_count'],
                      // response.data.userweek_count['12userweek_count'],
                    ],
                  },
                ],
                chartOptions: {
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  xaxis: {
                    categories: [
                      "wk1",
                      "wk2",
                      "wk3",
                      "wk4",
                      "wk5",
                      "wk6",
                      "wk7",
                      "wk8",
                      "wk9",
                      "wk10",
                      "wk11",
                      "wk12",
                    ],
                  },

                  tooltip: {
                    x: {
                      format: "dd/MM/yy HH:mm",
                    },
                  },
                },
              }),
                  (this.radialBarChart = {
                    series: engagementVal,
                    chartOptions: {
                      plotOptions: {
                        radialBar: {
                          dataLabels: {
                            name: {
                              fontSize: "22px",
                            },
                            value: {
                              fontSize: "16px",
                            },
                          },
                        },
                      },
                      legend: {
                        show: true,
                        position: "bottom",
                        horizontalAlign: "center",
                      },
                      labels: engagementcategoryName,
                    },
                  }),
                  (this.TotalEmployees = response.data.statisticsData[0].totalEmp);
              this.AllTotalEmployees =
                  response.data.statisticsData[0].alltotalEmp;

              //this.ActiveEmployees =  Math.ceil(response.data.addActiveemp *  (response.data.statisticsData[0].totalEmp / 100 ))


              this.TotalwatchedVideo = response.data.statisticsData[0].watched_count;

              if (response.data.statisticsData[0].room_interaction_count == null) {
                this.Totalroomintcount = 0;
              } else {
                this.Totalroomintcount = response.data.statisticsData[0].room_interaction_count;
              }

              if (response.data.statisticsData[0].room_count == null) {
                this.Totalroomcount = 0;
              } else {
                this.Totalroomcount = response.data.statisticsData[0].room_count;
              }

              this.Totaleventcount = response.data.totatActiveEvents;
              this.Totalchatcount = response.data.chatcount;
              this.Totalcallcount = response.data.callcount;

              this.$vs.loading.close();
            })
            .catch((error) => {
              console.log(error);
            });
      },
    },
  },
  mounted() {
    // Clear the browser cache data in localStorage when closing the browser window
    var getloginDate = localStorage.getItem("login_date");
    var currentDate1 = new Date();
    var currentMonth = currentDate1.getMonth() + 1;
    if (currentMonth < 10) {
      currentMonth = "0" + currentMonth;
    } else {
      currentMonth = "" + currentMonth;
    }
    const todate = `${currentDate1.getFullYear()}-${currentMonth}-${currentDate1.getDate()}`;
    if (todate == getloginDate) {
      window.onbeforeunload = function (e) {
        var storage = window.localStorage;
        storage.clear();
        localStorage.setItem("load_create", 1);
      };
    }
  },
  methods: {
    //handles the task of hiding and showing correct tab on click on tab heading
    tabclick(t) {
      this.visible_tab = t
    },

    datepikUpdate(arg) {
      console.log((arg))
      this.is_datepik_visible = false

      this.date_from    = moment(arg.from).utc().format('YYYY-MM-DD HH:mm:ss')
      this.date_to      = moment(arg.to).utc().format('YYYY-MM-DD HH:mm:ss')
      this.date_window  = arg.panel

      this.load()
    },
    load(){

      // alert(this.date_from + ' | ' + this.date_to + ' | ' + this.date_window)
      this.is_loading = true
      this.$vs.loading();
      this.$store.dispatch('dashboard/initializeDashboard', 
      {
        org_id: this.$store.state.AppActiveUser.org_id,
        date_from: this.date_from,
        date_to: this.date_to,
        date_window: this.date_window,
      }).then(()=>{
        this.is_loading = false
        this.$vs.loading.close();
      })
    },
    closeDatePik() {
      this.is_datepik_visible = false
    },
    formatReadable(date,fmt){
      if(moment(date).unix() > moment().unix()){
        return 'Today'
      }
      return moment(date).format(fmt);
    },
    updateData: function (timeline) {
      if (timeline == "rest") {
        this.$vs.loading();
      }

      this.selection = timeline;
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var today = new Date();
      var oneyear = today.getFullYear() - 1;
      switch (timeline) {
        case "one_month":
          document.getElementsByClassName("one_month")[0].style.background =
              "#4590f9";
          document.getElementsByClassName("three_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("six_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("one_year")[0].style.background =
              "#fff";

          this.$refs.chart.zoomX(
              new Date(
                  "01 " + monthNames[today.getMonth()] + today.getFullYear()
              ).getTime(),
              new Date().getTime()
          );
          break;
        case "six_months":
          document.getElementsByClassName("one_month")[0].style.background =
              "#fff";
          document.getElementsByClassName("three_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("six_months")[0].style.background =
              "#4590f9";
          document.getElementsByClassName("one_year")[0].style.background =
              "#fff";
          this.$refs.chart.zoomX(
              new Date(
                  today.getDate() +
                  " " +
                  monthNames[today.getMonth() - 6] +
                  today.getFullYear()
              ).getTime(),
              new Date(
                  today.getDate() +
                  monthNames[today.getMonth()] +
                  today.getFullYear()
              ).getTime()
          );
          break;
        case "one_year":
          document.getElementsByClassName("one_month")[0].style.background =
              "#fff";
          document.getElementsByClassName("three_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("six_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("one_year")[0].style.background =
              "#4590f9";
          this.$refs.chart.zoomX(
              new Date(
                  today.getDate() + monthNames[today.getMonth()] + oneyear
              ).getTime(),
              new Date(
                  today.getDate() +
                  monthNames[today.getMonth()] +
                  today.getFullYear()
              ).getTime()
          );
          break;
        case "three_months":
          document.getElementsByClassName("one_month")[0].style.background =
              "#fff";
          document.getElementsByClassName("three_months")[0].style.background =
              "#4590f9";
          document.getElementsByClassName("six_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("one_year")[0].style.background =
              "#fff";
          this.$refs.chart.zoomX(
              new Date(
                  today.getDate() +
                  monthNames[today.getMonth() - 3] +
                  today.getFullYear()
              ).getTime(),
              new Date(
                  today.getDate() +
                  monthNames[today.getMonth()] +
                  today.getFullYear()
              ).getTime()
          );
          break;
        default:
          // alert()
          var todateFilter = localStorage.getItem("to_date");
          var fromdateFilter = localStorage.getItem("from_date");
          document.getElementsByClassName("one_month")[0].style.background =
              "#fff";
          document.getElementsByClassName("three_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("six_months")[0].style.background =
              "#fff";
          document.getElementsByClassName("one_year")[0].style.background =
              "#fff";
          this.$http
              .get(
                  "organization/dashboard?org_id=" +
                  this.$store.state.AppActiveUser.org_id +
                  "&&todate=" +
                  todateFilter +
                  "&&fromdate=" +
                  fromdateFilter
              )
              .then((response) => {
                var videoClicks = [];
                for (
                    let emp = 0;
                    emp < response.data.video_clicks.length;
                    emp++
                ) {
                  videoClicks.push(
                      response.data.video_clicks[emp].video_click_count
                  );
                }
                var audioClicks = [];
                for (
                    let emp = 0;
                    emp < response.data.music_clicks.length;
                    emp++
                ) {
                  audioClicks.push(
                      response.data.music_clicks[emp].music_clicks_count
                  );
                }
                var videolikes = [];
                for (let emp = 0; emp < response.data.video_likes.length; emp++) {
                  videolikes.push(
                      response.data.video_likes[emp].video_like_count
                  );
                }

                this.UserInteractionGraph = {
                  chartOptions: {
                    series: [
                      {
                        name: "Video Clicks",
                        data: videoClicks,
                      },
                      {
                        name: "Audio Clicks",
                        data: audioClicks,
                      },
                      {
                        name: "Video likes",
                        data: videolikes,
                      },
                    ],
                    chart: {
                      id: "area-datetime",
                      type: "area",
                      height: 350,
                      zoom: {
                        autoScaleYaxis: true,
                      },
                    },
                    annotations: {
                      yaxis: [
                        {
                          y: 30,
                          borderColor: "#999",
                          label: {
                            show: true,
                            text: "Support",
                            style: {
                              color: "#fff",
                              background: "#00E396",
                            },
                          },
                        },
                      ],
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    markers: {
                      size: 0,
                      style: "hollow",
                    },
                    xaxis: {
                      type: "datetime",
                      // min: graphDate,//new Date('09 Aug 2021').getTime(),
                      tickAmount: 6,
                    },
                    yaxis: {
                      labels: {
                        formatter: function (val, index) {
                          return val.toFixed(0);
                        },
                      },
                    },
                    tooltip: {
                      x: {
                        format: "dd MMM yyyy",
                      },
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100],
                      },
                    },
                  },

                  selection: "one_year",
                };
                this.$vs.loading.close();
              })
              .catch((error) => {
                console.log(error);
              });
      }
      this.$vs.loading.close();
    },
    addDataPrompt() {
      this.promptName = "Take Survey";
      this.displayPrompt = true;
    },
    pdfprint(org_name) {
      document.getElementsByClassName("vs-sidebar")[0].style.display = "none";
      document.getElementsByClassName("dashboard-filters")[0].style.display =
          "none";
      document.getElementsByClassName("vx-navbar-wrapper")[0].style.display =
          "none";
      document.getElementsByClassName("insight")[0].style.display = "none";
      document.getElementsByClassName(
          "content-area-reduced"
      )[0].style.marginLeft = "0px";

      document.getElementsByClassName("empty_div")[0].style.height = "190px";
      document.getElementsByClassName("empty_div_retention ")[0].style.height =
          "190px";
      document.getElementsByClassName("empty_div_content")[0].style.height =
          "200px";

      window.print();

      document.getElementsByClassName("vs-sidebar")[0].style.display = "block";
      document.getElementsByClassName("dashboard-filters")[0].style.display =
          "block";
      document.getElementsByClassName("vx-navbar-wrapper")[0].style.display =
          "block";
      document.getElementsByClassName("insight")[0].style.display = "block";
      document.getElementsByClassName(
          "content-area-reduced"
      )[0].style.marginLeft = "260px";
      document.getElementsByClassName("empty_div")[0].style.height = "0px";
      document.getElementsByClassName("empty_div_retention ")[0].style.height =
          "0px";
      document.getElementsByClassName("empty_div_content")[0].style.height =
          "0px";
    },
  },
  created() {
    this.$vs.loading();


    // var currentDate = new Date();
    // var d = new Date();
    // var yesterdayDate = d.setMonth(d.getMonth() - 3); // currentDate.setDate(currentDate.getDate()- 14);
    // this.to_model = new Date(yesterdayDate);
    // var test = d.setMonth(d.getMonth() - 3);
    //
    // var todateget = new Date(yesterdayDate);
    // this.from_model = new Date();
    // var currentDate1 = new Date();
    //
    // var currentMonth = currentDate1.getMonth() + 1;
    // let currentMonth1 = currentMonth - 3
    // let currentMonth2 = currentMonth
    //
    //
    //
    // if (currentMonth2 < 10) {
    //   currentMonth2 = "0" + currentMonth2;
    // } else {
    //   currentMonth2 = "" + currentMonth2;
    // }
    // const todate = `${currentDate1.getFullYear()}-${ currentMonth2 }-${ currentDate1.getDate() + 1 }`;
    //
    // if (currentMonth1 < 10) {
    //   currentMonth1 = "0" + currentMonth1;
    // } else {
    //   currentMonth1 = "" + currentMonth1;
    // }
    // const fromdate = `${todateget.getFullYear()}-${ currentMonth1 }-${todateget.getDate()}`;
    //

    // localStorage.setItem("to_date", todate);
    // localStorage.setItem("from_date", fromdate);
    // // Employee
    //
    // var todateFilter = localStorage.getItem("to_date");

    var fromdateFilter = '1000-01-01 00:00:00'// moment().subtract(3, 'months').format('YYYY-MM-DD')
    var todateFilter = moment().format('YYYY-MM-DD')


    this.$http
        .get(
            "organization/dashboard?org_id=" +
            this.$store.state.AppActiveUser.org_id +
            "&&todate=" +
            todateFilter +
            "&&fromdate=" +
            fromdateFilter
        )
        .then((response) => {
          console.log("aaaaaaaaaaaaa", response.data.employeesUtilize.length);
          var engagementVal = [];
          var engagementcategoryName = [];
          for (let emp = 0; emp < response.data.employeesUtilize.length; emp++) {
            engagementVal.push(response.data.employeesUtilize[emp].count);
            engagementcategoryName.push(
                response.data.employeesUtilize[emp].category_name
            );
          }

          if (response.data.employeesUtilize.length !== 0) {
            this.showContent = false;
          } else {
            this.showContent = true;
          }

          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          var videoClicks = [];
          for (let emp = 0; emp < response.data.video_clicks.length; emp++) {
            videoClicks.push(response.data.video_clicks[emp].video_click_count);
          }
          var audioClicks = [];
          for (let emp = 0; emp < response.data.music_clicks.length; emp++) {
            audioClicks.push(response.data.music_clicks[emp].music_clicks_count);
          }
          var videolikes = [];
          for (let emp = 0; emp < response.data.video_likes.length; emp++) {
            videolikes.push(response.data.video_likes[emp].video_like_count);
          }

          this.chatemployeelog = response.data.chatemployeelog;

          if (response.data.orgInsights != null) {
            this.orgInsights = response.data.orgInsights;
          }
          (this.UserInteractionGraph = {
            chartOptions: {
              series: [
                {
                  name: "Video Clicks",
                  data: videoClicks,
                },
                {
                  name: "Audio Clicks",
                  data: audioClicks,
                },
                {
                  name: "Video likes",
                  data: videolikes,
                },
              ],
              chart: {
                id: "area-datetime",
                type: "area",
                height: 350,
                zoom: {
                  autoScaleYaxis: true,
                },
              },
              annotations: {
                yaxis: [
                  {
                    y: 30,
                    borderColor: "#999",
                    label: {
                      show: true,
                      text: "Support",
                      style: {
                        color: "#fff",
                        background: "#00E396",
                      },
                    },
                  },
                ],
                // xaxis: [{
                //   x: new Date('14 Nov 2021').getTime(),
                //   borderColor: '#999',
                //   yAxisIndex: 0,
                //   label: {
                //     show: true,
                //     text: 'Rally',
                //     style: {
                //       color: "#fff",
                //       background: '#775DD0'
                //     }
                //   }
                // }]
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
                style: "hollow",
              },
              xaxis: {
                type: "datetime",
                // min:new Date(minDateval).getTime(),
                tickAmount: 6,
              },
              yaxis: {
                labels: {
                  formatter: function (val, index) {
                    return val.toFixed(0);
                  },
                },
              },
              tooltip: {
                x: {
                  format: "dd MMM yyyy",
                },
              },
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 100],
                },
              },
            },

            selection: "one_year",
          }),
              (this.RetentionlineAreaChartSpline = {
                series: [
                  {
                    name: "Projected Retention",
                    data: [70, 60, 50, 40, 40, 40, 40, 40, 40, 40, 40, 40],
                  },
                  {
                    name: "Active Users",
                    data: [
                      response.data.userweek_count["1userweek_count"],
                      response.data.userweek_count["2userweek_count"],
                      response.data.userweek_count["3userweek_count"],
                      response.data.userweek_count["4userweek_count"],
                      response.data.userweek_count["5userweek_count"],
                      response.data.userweek_count["6userweek_count"],
                      response.data.userweek_count["7userweek_count"],
                      response.data.userweek_count["8userweek_count"],
                      response.data.userweek_count["9userweek_count"],
                      response.data.userweek_count["10userweek_count"],
                      response.data.userweek_count["11userweek_count"],
                      response.data.userweek_count["12userweek_count"],
                    ],
                  },
                ],
                chartOptions: {
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  xaxis: {
                    categories: [
                      "wk1",
                      "wk2",
                      "wk3",
                      "wk4",
                      "wk5",
                      "wk6",
                      "wk7",
                      "wk8",
                      "wk9",
                      "wk10",
                      "wk11",
                      "wk12",
                    ],
                  },
                  tooltip: {
                    x: {
                      format: "dd/MM/yy HH:mm",
                    },
                  },
                },
              }),
              (this.radialBarChart = {
                series: engagementVal,
                chartOptions: {
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                        },
                        value: {
                          fontSize: "16px",
                        },
                      },
                    },
                  },
                  legend: {
                    show: true,
                    position: "bottom",
                    horizontalAlign: "center",
                  },
                  labels: engagementcategoryName,
                },
              }),
              (this.TotalEmployees = response.data.statisticsData[0].totalEmp);
          this.AllTotalEmployees = response.data.statisticsData[0].alltotalEmp;
          if (response.data.addActiveemp == 0) {
            this.ActiveEmployees = 0;
          } else {
            this.ActiveEmployees = Math.ceil(
                (response.data.addActiveemp /
                    response.data.statisticsData[0].totalEmp) *
                100
            );
          }


          this.TotalwatchedVideo = response.data.statisticsData[0].watched_count;

          if (response.data.statisticsData[0].room_interaction_count == null) {
            this.Totalroomintcount = 0;
          } else {
            this.Totalroomintcount = response.data.statisticsData[0].room_interaction_count;
          }

          if (response.data.statisticsData[0].room_count == null) {
            this.Totalroomcount = 0;
          } else {
            this.Totalroomcount = response.data.statisticsData[0].room_count;
          }
          this.Totaleventcount = response.data.totatActiveEvents;
          this.Totalchatcount = response.data.chatcount;
          this.Totalcallcount = response.data.callcount;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
        });
  },
};
</script>
<style>
.btn_dashboard {
  float: right;
  position: relative;
  left: 43px;
  top: -3px;
}
</style>
<style>
.tab_heading_item {
  font-weight: bold;
  width: 100%;
  text-align: center;

}

.tab_heading_item_parent {
  background: white;
  cursor: pointer;
}

.tab_heading_active{

  background: #1c54a2;
  padding: 10px;
  color: white;
  display: inline-block;
  border-radius: 10px;
}

.tab_heading_container{
  margin-bottom: 20px;
  padding: 5px;
  background: #ffffff;
}

.title_range{
  color: silver;
}
</style>
